import { Button, Row, Col, Container } from "react-bootstrap";
import { CartContext } from "../CartContex";
import SliderImageGallery from './SliderImageGallery';
import { useContext, useState } from "react";
import MarkdownRenderer from './MarkdownRenderer';
import SelectBox from "./SelectBox";
import { useParams } from 'react-router-dom';
import { getProductData, getProductPrice } from "../productsStore";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import ReactGA from "react-ga4";

function ProductShow(){
  const { id } = useParams();
  const product = getProductData(id);
  const cart = useContext(CartContext);
  const defaultVariations = product.variations
    ? Object.fromEntries(product.variations.map((v) => [v.key, v.options[0].value]))
    : {};


  const [price, setPrice] = useState("price" in product ? product.price : getProductPrice(product.id, defaultVariations));
  const [priceWdiscount, setPriceWdiscount] = useState("price" in product ? product.price : getProductPrice(product.id, defaultVariations, true));

  const hasDiscount = 'discount' in product

  const [selectedOptions, setSelectedOptions] = useState(defaultVariations);
  const handleOptionChange = (variationKey, event) => {
    const newVariation = {
      ...selectedOptions,
      [variationKey]: event.target.value,
    }
    setSelectedOptions(newVariation);

    const newPrice = getProductPrice(product.id, newVariation, false);
    const newPriceWdiscount = getProductPrice(product.id, newVariation, true);
    setPrice(newPrice);
    setPriceWdiscount(newPriceWdiscount)

    ReactGA.event({
      category: "ProductShow",
      action: "Variation changed",
    })

  };

  return (
    <Container className="mb-4">
      <Row>
        <Col>
          <Link to="/">← Back</Link>
        </Col>
      </Row>
      <Row>
        <Col style={{positon:"relative"}}>
          <h1 className="mb-4 mt-2 position-relative text-wrap" style={{display: "inline-block"}}>
            { product.title }
            { product.bestseller &&
                <span className="badge bg-warning text-uppercase fs-6 ms-2">Bestseller <FontAwesomeIcon icon={faTrophy} /></span>
            }
            { product.coming_soon &&
                <span className="badge bg-secondary text-uppercase fs-6 ms-2">
                  Coming soon <FontAwesomeIcon icon={faHourglassHalf} />
                </span>
            }
          </h1>
        </Col>
      </Row>
      <Row>
        { product.images.length > 0 &&
          <Col lg={6}>
            <SliderImageGallery images={product.images} />
          </Col>
        }
        <Col>
          <h3>
            ${priceWdiscount.toFixed(2)}
            { hasDiscount &&
               <span className="ms-2 text-secondary fs-6">
                <span class="badge rounded-pill bg-danger fs-8">{product.discount}% off</span>
                <span className="text-decoration-line-through ms-2">
                  ${price.toFixed(2)}
                </span>
              </span>
            }

          </h3>
            { "variations" in product &&
              <>
                {product.variations.map((variation) => (
                  <SelectBox key={variation.key} options={variation.options} value={selectedOptions[variation.key] || ''} onChange={(value) => handleOptionChange(variation.key, value)} label={variation.label} />
                  ))}
              </>
            }

          {!("disabled" in product) &&
            <Button variant="primary" className="mb-2 mt-2" onClick={() => {
              cart.addOneToCart(product.id, selectedOptions)
              cart.showCart(true)
            }}>Add To Cart</Button>
          }
          <MarkdownRenderer content={product.description} />
        </Col>
      </Row>
      { "extras" in product &&
        <Row>
          <Col style={{textAlign: "center"}} className="product-extras">
            <hr className="m-3" style={{width: '90%', margin: '0 auto'}}></hr>
            <MarkdownRenderer content={product.extras} />
          </Col>
        </Row>
      }
    </Container>
  )
}


export default ProductShow;