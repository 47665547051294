import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import './AnnouncementBanner.css';

function AnnouncementBanner() {
  const endDate = new Date('2025-05-01T23:59:59');
  const currentDate = new Date();

  if (currentDate > endDate) {
    return null;
  }

  return (
    <div className="announcement-banner">
      <Container>
        <Alert variant="info" className="mb-0 text-center py-3">
          <div className="announcement-content">
            <p className="mb-2">
              🌴 Vacation Notice: We're away until mid-May
            </p>
            <p className="mb-2 highlight">
              Pre-order now with <strong>25% OFF!</strong> 🎁
            </p>
            <p className="mb-0 signature">
              Orders will be fulfilled upon return. Thank you for your patience! 🚀
            </p>
          </div>
        </Alert>
      </Container>
    </div>
  );
}

export default AnnouncementBanner; 