const productsArray = [
  {
    id: "billy_cover_caps",
    bestseller: true,
    discount: 25,
    title: "Ikea Billy Bookcase Cover caps",
    description: `After your cabinets have been installed and your shelves have been put in place, you can enhance the appearance of the interior by filling any unused drill holes with these cover caps.

This process is simple and will provide a polished finish to your cabinets.

There are two versions of the Ikea Billy Bookcase:
- 41 3/4" (106cm) tall, with 96 drill holes requiring 1 pack of 110 cover caps
- 79 1/2" (202cm) tall, with 192 drill holes needing 220 cover cap packs

Use this information to determine the number of cover caps required for your bookcase configuration.

Don't hesitate to get in touch with us for assistance in determining the precise number of cover caps required for your configuration
`,
    images: [
      { src: '/products/billy/IMG_1998-2%20(Large).jpg', alt: 'Image 1' },
      { src: '/products/billy/IMG_1298-4.jpg', alt: 'Image 2' },
      { src: '/products/billy/IMG_2002-2.jpg', alt: 'Image 3' },
      { src: '/products/billy/before_after.jpg', alt: 'Image 4' },
      { src: '/products/billy/before_after_closeup (Large).jpg', alt: 'Image 4' },
      { src: '/products/billy/Height106.png', alt: 'Image 4' },
      { src: '/products/billy/Height202.png', alt: 'Image 4' },
      { src: '/products/billy/DSC01791.jpg', alt: 'Image 4' },

    ],
    heroImage: {
      src: '/products/billy/IMG_1998-2%20(Large).jpg',
      alt: 'Ikea Billy Bookcase Cover caps'
    },
    variations: [
      {
        key: "color",
        label: "Color",
        options: [
          {
            value: 'white',
            label: 'White',
          },
          {
            value: 'brown',
            label: 'Birch veneer',
          },
          {
            value: 'black',
            label: 'Black-brown',
          },
        ]
      },
      {
        key: "pack",
        label: "Pack",
        options: [
          {
            value: '110',
            label: '110',
            price: 9.99,
          },
          {
            value: '220',
            label: '220',
            price: 19.99,
          },
        ]
      }
    ],
    prices: {
      white110: 'price_1N1xVzHxBMkvkm4RX8EhnKED',
      white220: 'price_1N38pRHxBMkvkm4Ru4grlZXt',
      brown110: 'price_1N38zKHxBMkvkm4RHsZTaBel',
      brown220: 'price_1N390EHxBMkvkm4R61G7BHqb',
      black110: 'price_1N390zHxBMkvkm4RvYvk2Vk3',
      black220: 'price_1N391LHxBMkvkm4Rx9rEa8Wo'
    },
    extras: `## Over 20'000 Cover Caps shipped to satisfied customers
\
&nbsp;
\
&nbsp;
### Available on [Etsy](https://www.etsy.com/ca/listing/1407771196/ikea-billy-bookcase-cover-caps)!
### 5.0★ Rating on Etsy
\
&nbsp;
\
&nbsp;
### Reviews:
![Reviews](/products/billy/billy_reviews.png)
`,
  },
  // {
  //   disabled: true,
  //   coming_soon: true,
  //   id: "vertuo-organizer-v2",
  //   title: "Vertuo Organizer v2",
  //   price: 49.99,
  //   description: `Foo bar`,
  //   images: [

  //   ],
  //   heroImage: {
  //     src: '/products/vertuo2/vertuo2.jpg',
  //     alt: 'XXX'
  //   },
  //   prices: {
  //     white110: 'price_XXXXX',
  //   },
  //   price_urls: {
  //     white110: 'https://buy.stripe.com/XXXXX',
  //   }
  // },
  {
    id: "besta_cover_caps",
    // bestseller: false,
    discount: 25,
    title: "Cover Caps for Besta/Brimnes",
    description: `Once your cabinets are installed and shelves are securely positioned, elevate the interior's aesthetic by concealing any unused drill holes with these cover caps. This straightforward procedure adds a refined touch to your cabinets, ensuring a polished finish.

Our cover caps fill 3mm drill holes and works greaks for bookcases like:
Besta, Brimnes

Don't hesitate to get in touch with us for assistance in determining the precise number of cover caps required for your configuration.`,
    images: [
      { src: '/products/besta/3ap7.jpg', alt: 'Image 1' },
      { src: '/products/besta/fu4c.jpg', alt: 'Image 2' },
      { src: '/products/besta/bkv0.jpg', alt: 'Image 3' },
    ],
    heroImage: {
      src: '/products/besta/bkv0.jpg',
      alt: 'Cover Caps for Besta/Brimnes'
    },
    variations: [
      {
        key: "color",
        label: "Color",
        options: [
          {
            value: 'white',
            label: 'White',
          },
          // {
          //   value: 'brown',
          //   label: 'Birch veneer',
          // },
          // {
          //   value: 'black',
          //   label: 'Black-brown',
          // },
        ]
      },
      {
        key: "pack",
        label: "Pack",
        options: [
          {
            value: '110',
            label: '110',
            price: 9.99,
          },
          {
            value: '220',
            label: '220',
            price: 19.99,
          },
        ]
      }
    ],
    prices: {
      white110: 'price_1P8r44HxBMkvkm4R4KC7MjbZ',
      white220: 'price_1P8r56HxBMkvkm4RbHnLMHqM',
      // brown110: 'price_1N38zKHxBMkvkm4RHsZTaBel',
      // brown220: 'price_1N390EHxBMkvkm4R61G7BHqb',
      // black110: 'price_1N390zHxBMkvkm4RvYvk2Vk3',
      // black220: 'price_1N391LHxBMkvkm4Rx9rEa8Wo'
    },
  },
  {
    coming_soon: false,
    id: "swan-lake-chocolate-cutter-set",
    title: "Swan Lake Chocolate Cutter Set",
    price: 12.99,
    discount: 25,
    description: `Elevate your pastry creations with our Swan Lake Chocolate Cutter Set, designed to bring a touch of elegance and sophistication to your desserts. This set includes two cutters, one for the left wing and one for the right wing, allowing you to create beautiful, symmetrical swan wings from thin layers of chocolate.

Crafted from durable, food-grade PETG plastic, these cutters are perfect for both professional bakers and home enthusiasts. Each wing measures 8cm long and 4cm wide, providing the perfect size for adding a graceful swan wing to your cakes, cupcakes, and other pastries.

Features:
- Elegant Design: Inspired by Swan Lake, these cutters help you create stunning, intricate chocolate swan wings that add a professional touch to your desserts.
- Symmetrical Pair: Includes both left and right wing cutters to maintain the perfect texture and shape for a balanced look.
- High-Quality Material: Made from food-grade PETG plastic, ensuring durability and safety for all your culinary creations.
- Perfect Size: Each wing is 8cm long and 4cm wide, ideal for a variety of dessert decorations.
- Easy to Use: The sharp edges and ergonomic design make cutting through chocolate smooth and effortless, perfect for delicate pastries.
- Versatile: Ideal for a variety of desserts, from cakes and cupcakes to cookies and more.

Transform your desserts into masterpieces with the Swan Lake Chocolate Cutter Set. Perfect for special occasions, weddings, or simply adding a touch of magic to your everyday baking. Order now and let your creativity take flight!`,
    images: [
      { src: '/products/swan_wing_cutter/il_fullxfull.6063951166_5pts.webp', alt: 'Image 2' },
      { src: '/products/swan_wing_cutter/il_fullxfull.6063951116_b93x.webp', alt: 'Swan Desert' },
      { src: '/products/swan_wing_cutter/il_fullxfull.6112017957_ss1s.webp', alt: 'Image 1' },
      { src: '/products/swan_wing_cutter/il_fullxfull.6112017981_agg7.jpg', alt: 'Image 3' },

    ],
    heroImage: {
      src: '/products/swan_wing_cutter/il_fullxfull.6063951166_5pts.webp',
      alt: 'Swan Desert'
    },
    prices: {
      pair: 'price_1R42bNHxBMkvkm4RwqAFGwgu',
    },
    // price_urls: {
    //   white110: 'https://buy.stripe.com/XXXXX',
    // }
  },
]

function getProductPrice(id, variation, withDiscount = false) {
  let productData = productsArray.find(product => product.id === id)
  let price = 0;

  if (productData.variations === undefined && "price" in productData){
    return productData.price
  }

  for (const v in productData.variations){
    for(const op in productData.variations[v].options){
      if (!("price" in productData.variations[v].options[op])){
        continue
      } else if (variation[productData.variations[v].key] == productData.variations[v].options[op].value){
        price = productData.variations[v].options[op].price
      }
    }
  }

  if (withDiscount == true && "discount" in productData) {
    let discount = productData.discount
    price = (100-discount)/100 * price
  }

  return price
}

// function getProductPrices(id) {
//   let productData = productsArray.find(product => product.id === id)
//   let prices = [];

//   for (const v in productData.variations){
//     for(const op in productData.variations[v].options){
//       if (!("price" in productData.variations[v].options[op])){
//         continue
//       } else {
//         prices.push(productData.variations[v].options[op].price)
//       }
//     }
//   }

//   return prices
// }


function getProductData(id) {
  let productData = productsArray.find(product => product.id === id)

  if (productData == undefined){
    console.log("Product data does not exists for ID: " + id)
    return undefined;
  }

  return productData;
}

export { productsArray, getProductData, getProductPrice }; //, getProductPrices};